<template>
  <div
    :class="[
      boxStyle == 'two'
        ? 'shop-box-two'
        : boxStyle == 'three'
        ? 'shop-box-three'
        : boxStyle == 'four'
        ? 'shop-box-four'
        : 'shop-box-one'
    ]"
  >
    <div v-if="isLoading && is_empty_obj(shopDetails)">
      <v-skeleton-loader type="image" height="310" />
    </div>
    <div
      class="overflow-hidden"
      :class="[boxStyle == 'three' ? 'box-three-bg' : '', boxStyle !== 'four' ? 'border rounded' : '', `${boxStyle}-style`]"
      v-else
    >
      <v-row no-gutters align="center" v-if="boxStyle == 'four'" class="bg-white px-3 py-3">
        <v-col
          :sm="null"
          cols="12"
          class="minw-0 position-relative"
        >
          <div class="lh-0 position-relative">
            <router-link
              :to="{ name: 'ShopDetails', params: { slug: shopDetails.slug } }"
              class="text-reset d-block"
            >
              <img
                :src="addResourceImage(shopDetails.banner)"
                @error="imageFallback($event)"
                :alt="shopDetails.name"
                class="img-fit h-140px rounded-lg"
              />
            </router-link>
           
          </div>
          <div
            class="text-center fs-12 absolute-left-center align-center d-flex ms-4"
            style="padding-bottom: 30px;"
          >
            <router-link
              :to="{ name: 'ShopDetails', params: { slug: shopDetails.slug } }"
              class="text-reset"
            >
              <img
                :src="addResourceImage(shopDetails.logo)"
                :alt="shopDetails.name"
                @error="imageFallback($event)"
                :class="[
                  'border rounded-circle shadow-vi border-2 size-60px',
                ]"
              />
            </router-link>
          </div>

          <div class="four-img-bottom rounded-b-lg d-flex align-center">
            <router-link
                :to="{
                  name: 'ShopDetails',
                  params: { slug: shopDetails.slug }
                }"
                class="text-reset"
              >
                <h4
                  :class="[
                    'fs-20 text-truncate text-red pl-4 max-width-x',
                    { 'opacity-0': !shopDetails.name }
                  ]"
                >
                  {{ shopDetails.name || 'null' }}
                </h4>
              </router-link>

              <div
                class="d-flex fs-12 ml-2"
              >
                <v-rating
                  class="lh-1-4"
                  background-color=""
                  empty-icon="las la-star"
                  full-icon="las la-star active-four"
                  half-icon="las la-star half-four"
                  hover
                  half-increments
                  readonly
                  size="15"
                  length="5"
                  :value="shopDetails.rating"
                ></v-rating>
                <span class="me-2 text-red ml-1">
                  {{ shopDetails.rating.toFixed(1) }}
                </span>
              </div>

              <div class="text-end flex-fill">
              <v-btn
                small
                link
                elevation="0"
                :to="{
                  name: 'ShopDetails',
                  params: { slug: shopDetails.slug }
                }"
                class="text-red transparent"
              >
                <span class="text-red">{{ $t('visit_store') }}</span>
                <i class="text-red las la-arrow-right"></i>
              </v-btn>
            </div>
          </div>
        </v-col>
        <v-col
          cols="12"
          :sm="null"
          class="minw-0"
        >
          <div>
            <div class="mt-3 mb-3 d-flex align-center">
              <div class="flex-fill line-left"></div>
              <div class="flex-fill line-right"></div>
            </div>
            <v-row
              :no-gutters="false"
              class="gutters-10"
              v-if="shopDetails.top_3_products?.data.length"
            >
              <v-col
                cols="12"
                v-for="(product, i) in shopDetails.top_3_products.data"
                :sm="4"
                :key="i"
              >
                <product-box-vi-two
                  :product-details="product"
                  :is-loading="isLoading"
                />
              </v-col>
            </v-row>
            <div style="height: 73px" v-else></div>
           
          </div>
        </v-col>
      </v-row>

      <v-row no-gutters align="center" v-else>
        <v-col
          :sm="boxStyle == 'three' ? '6' : null"
          cols="12"
          class="minw-0 position-relative"
        >
          <div class="lh-0 position-relative" v-if="boxStyle != 'three'">
            <router-link
              :to="{ name: 'ShopDetails', params: { slug: shopDetails.slug } }"
              class="text-reset d-block"
            >
              <img
                :src="addResourceImage(shopDetails.banner)"
                @error="imageFallback($event)"
                :alt="shopDetails.name"
                class="img-fit h-150px"
              />
            </router-link>
            <div
              v-if="boxStyle == 'two'"
              class="w-100 white--text d-flex justify-center align-center py-2 fs-12 px-3"
            >
              <v-rating
                class="lh-1-2"
                background-color=""
                empty-icon="las la-star"
                full-icon="las la-star active"
                half-icon="las la-star half"
                hover
                half-increments
                readonly
                size="15"
                length="5"
                color="#FF3C3C"
                :value="shopDetails.rating"
              ></v-rating>
              <span class="ml-1 fw-600 fc-vi-red">
                {{ shopDetails.rating.toFixed(2) }}
              </span>
            </div>
          </div>
          <div
            :class="[
              'text-center fs-12',
              boxStyle == 'three'
                ? 'pa-4' : 'pa-5 position-relative'
            ]"
          >
            <router-link
              :to="{ name: 'ShopDetails', params: { slug: shopDetails.slug } }"
              class="text-reset"
              v-if="boxStyle != 'two'"
            >
              <img
                :src="addResourceImage(shopDetails.logo)"
                :alt="shopDetails.name"
                @error="imageFallback($event)"
                :class="[
                  'border rounded-circle shadow-vi border-2 size-90px mb-2',
                  { 'mt-n15': boxStyle == 'one' }
                ]"
              />
            </router-link>
            <div>
              <router-link
                :to="{
                  name: 'ShopDetails',
                  params: { slug: shopDetails.slug }
                }"
                class="text-reset"
              >
                <h4
                  :class="[
                    'fs-21 fs-vi-fen mb-2',
                    boxStyle == 'three'
                      ? 'text-truncate-2 lh-1-4 h-60px fs-vi'
                      : 'text-truncate',
                    { 'opacity-0': !shopDetails.name }
                  ]"
                >
                  {{ shopDetails.name || 'null' }}
                </h4>
              </router-link>
              <div
                class="text-truncate-2 opacity-80 h-40px"
                v-if="boxStyle == 'one'"
              >
                <span
                  v-for="(category, i) in shopDetails.categories.data"
                  :key="i"
                >
                  {{ category.name }}
                  <span v-if="shopDetails.categories.data.length - i != 1">
                    ,
                  </span>
                </span>
              </div>
              <div
                :class="[
                  'd-flex fs-12 my-2 justify-center',
                ]"
                v-if="boxStyle != 'two'"
              >
                <v-rating
                  class="lh-1-4"
                  background-color=""
                  empty-icon="las la-star"
                  full-icon="las la-star active"
                  half-icon="las la-star half"
                  hover
                  half-increments
                  readonly
                  size="15"
                  length="5"
                  :value="shopDetails.rating"
                ></v-rating>
                <span :class="[boxStyle == 'three' ? 'ml-2 fs-vi' : 'me-2']">
                  {{ shopDetails.rating.toFixed(1) }}
                </span>
                <!-- <span
                  class="opacity-80"
                >({{ shopDetails.reviews_count }} {{ $t("ratings") }})</span
                > -->
              </div>
              <!-- <div class="opacity-80" v-if="boxStyle == 'one'">
                {{ $t('shop_since') + ' ' + shopDetails.since }}
              </div> -->
              <div class="opacity-80" v-if="boxStyle == 'one'">
                {{ $t('total_products') + ' ' + shopDetails.products_count }}
              </div>
            </div>
            <div
              :class="[
                boxStyle == 'two'
                  ? 'd-flex flex-column mt-5'
                  : boxStyle == 'three'
                  ? 'd-flex flex-column mt-3'
                  : 'mt-5'
              ]"
            >
              <v-btn
                elevation="0"
                :small="boxStyle == 'one' ? false : true"
                :to="{
                  name: 'ShopDetails',
                  params: { slug: shopDetails.slug }
                }"
                :class="[
                  boxStyle == 'one'
                    ? 'ms-4 white--text bg-vi border border-primary'
                    : boxStyle == 'two'
                    ? 'mt-2 bg-vi-z'
                    : boxStyle == 'three'
                    ? 'mt-2 bg-vi-z border border-primary text-white'
                    : 'mt-2 bg-soft-primary border border-primary'
                ]"
              >
                {{ $t('visit_store') }}
              </v-btn>
            </div>
          </div>
        </v-col>
        <v-col
          v-if="boxStyle == 'three'"
          cols="12"
          :sm="boxStyle == 'three' ? '6' : null"
          class="minw-0"
        >
          <div>
            <v-row
              :no-gutters="true"
              class="gutters-10"
              v-if="shopDetails.top_3_products?.data.length"
            >
              <v-col
                cols="12"
                v-for="(product, i) in shopDetails.top_3_products.data"
                :sm="null"
                :key="i"
              >
                <product-box-vi-two
                  :product-details="product"
                  :is-loading="isLoading"
                  :class="[
                    boxStyle == 'three' && i == 0
                      ? 'my-4 mlr-item'
                      : boxStyle == 'three'
                      ? 'mb-4 mlr-item'
                      : ''
                  ]"
                />
              </v-col>
            </v-row>
            <div style="height: 73px" v-else></div>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import ProductBoxViTwo from '../product/ProductBoxViTwo.vue'

export default {
  props: {
    boxStyle: { type: String, default: 'one' },
    isLoading: { type: Boolean, required: true, default: true },
    shopDetails: { type: Object, required: true, default: () => ({}) }
  },

  components: {
    ProductBoxViTwo
  },

  computed: {
    ...mapGetters('follow', ['isThisFollowed'])
  },
  methods: {
    ...mapActions('follow', ['addNewFollowedShop', 'removeFromFollowedShop'])
  }
}
</script>

<style scoped>
.box-three-bg {
  background: rgba(133, 238, 223, 0.1);
  backdrop-filter: blur(25px);
}
.shadow-vi {
  box-shadow: 0px 10px 70px -12px #00CCAE !important;
}

.fs-vi {
  color: #00CCAE !important;
}
.fs-vi-fen {
  color: #85EEDF;
}
.text-white {
  color: #fff;
}

.fc-vi-red {
  color: #FF5722;
}
.two-style {
  background: #fff;
}
.bg-white {
  background: #fff;
}
.bg-vi-z {
  border-radius: 50px;
  color: #fff;
  background: #85EEDF !important;
}

.bg-vi {
  color: #fff;
  background: #85EEDF !important;
}
.text-jin {
  color: #FFDA16;
}
.four-img-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 35px;
  right: 0;
  background: rgba(0,0,0,0.7);
}

.half-four:before {
  color: #e4e4e4 !important;
  color: #e4e4e4 !important;
}

.max-w-200 {
  max-width: 200px;
}
.line-left {
  height: 3px;
  background: #00CCAE;
}
.line-right {
  height: 1px;
  background: #d9d9d9;
}

.mlr-item {
  padding-right: 16px;
}

.max-width-x {
  max-width: 200px;
}
@media (max-width: 600px) {
  .mlr-item {
    padding-left: 16px;
  }

  .max-width-x {
    max-width: 100px
  }
}

.shop-box-one {
  background: #fff;
}

</style>